import { Component } from '@angular/core';

@Component({
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
  
})
export class LinksComponent {
  public pageTitle = 'Links';
}
