import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IAppConfig } from './AppConfig.model';
@Injectable()
export class AppConfig {
    // static settings: IAppConfig;
    static settings: IAppConfig = {
        env: {
            name: 'prod'
        },
        webApi: {
            baseUrl: 'https://webapi.stlbrews.org'
        //    baseUrl: 'https://localhost:44361'
        }
    };

    constructor(private http: HttpClient) {}
    load() {
        // const jsonFile = `assets/config/config.${environment.name}.json`;
        return AppConfig.settings;
        // return new Promise<void>((resolve, reject) => {
        //     this.http.get(jsonFile).toPromise().then((response : IAppConfig) => {
        //             AppConfig.settings = <IAppConfig>response;
        //        resolve();
        //     }).catch((response: any) => {
        //        reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
        //        AppConfig.settings = <IAppConfig>this.hardcodedSettings;
        //     });
        // });
    }
}