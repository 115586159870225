import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators'

import { IMeeting } from './meeting';
import { AppConfig } from '../AppConfig/AppConfig';

@Injectable( {
    providedIn: 'root'
})
export class MeetingService {

     private meetingUrl = '/Meetings';
   
    constructor(private http: HttpClient) {}

    getNextMeeting(): Observable<IMeeting> {
        const finalUrl = AppConfig.settings.webApi.baseUrl + this.meetingUrl;
        return this.http.get<IMeeting>(finalUrl).pipe(
            // tap(data=>console.log('Meetings JSON: ' + JSON.stringify(data))),
            catchError(this.handleError)
        );
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = 'An error occurred: " + err.message}';
        } else {
            errorMessage = 'Server returned code: ' + err.status + ', error message is ' + err.message;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
    
}
