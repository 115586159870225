import { IYearOfNewsletters } from './yearOfNewsletters';
import { NewsletterService } from './newsletter.service';
import { Component } from '@angular/core';
import { expressionType } from "@angular/compiler/src/output/output_ast";
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { INewsletter } from './newsletter';

imports: [CommonModule]

@Component({
  templateUrl: './newsletter.component.html',
  styleUrls: ['../app.component.css'] //, './newsletter.component.css']
})
export class NewsletterComponent {
    public pageTitle = 'Newsletters';
    public allNewsletters : IYearOfNewsletters[];
    errorMessage : String;
    years : Int32Array;

    public thisYearsNewsletters : INewsletter[];

    FormData: FormGroup;

    form = new FormGroup({

        Years: new FormControl('', Validators.required)
    
      });
    
      
    constructor(private builder: FormBuilder, private newsletterService: NewsletterService) {
    }
   
    ngOnInit() : void {
       
        this.newsletterService.getNewsletters().subscribe({
            next: newsletters => this.allNewsletters = newsletters,
            error: err => this.errorMessage  = err
        });

        this.newsletterService.getYears().subscribe({
            next: years => this.years = years,
            error: err => this.errorMessage  = err
        });

      }

    submit(){

        console.log(this.form.value);
    
      }
    
      
    
      changeYear(e) {
    
        console.log(e.target.value);
        this.newsletterService.getNewslettersForYear(e.target.value).subscribe({
            next: newsletters => this.thisYearsNewsletters = newsletters,
            error: err => this.errorMessage  = err
        });
     }
    
          

 
}