import { Component } from "@angular/core";
import { IMeeting } from "./meeting";
import { MeetingService } from './meeting.service'

@Component({
    selector: 'app-home-meeting',
    templateUrl: './meeting.component.html',
    styleUrls: ['../app.component.css']
  })
export class MeetingComponent {
    currentMeeting : IMeeting;
    errorMessage : String;

    allMeetings: IMeeting[];

    constructor(private meetingService: MeetingService) {
    }
   
    ngOnInit() : void {
        this.meetingService.getNextMeeting().subscribe({
            next: meeting => this.currentMeeting = meeting,
            error: err => this.errorMessage  = err
        });
        }
 
}