<div class="container">
    <h2 class="text center mb-5">Contact St. Louis Brews</h2>
    <div class="row">
        <div class="col-md-6 offset-md-3">
            <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
                <div class="form-group">
                    <label for="Recipient">Recipient</label>
                    <select formControlName="Recipient" class="form-control" name="Recipient"  [(ngModel)]='DefaultRecipient'>
                        <option disabled selected>Select Recipient</option>
                         <!-- <option [ngValue]=null>Choose Recipient</option> -->
                         <option *ngFor="let recipient of recipients" [ngValue]="recipient.officer">{{recipient.officer}} - {{recipient.name}}</option>
                         <option value='Officers'>All Officers</option>
                        </select>
                </div>
                <div class="form-group">
                    <label for="Email">Email</label>
                    <input type="email" class="form-control" name="Email" aria-describedby="emailHelp" placeholder="Enter email" formControlName="Email">
                    <small id="emailHelp" class="form-text test-muted">We will never share your email with anyone else.</small>
                </div>
                <div class="form-group">
                    <label for="fullname">Full Name</label>
                    <input type="text" class="form-control" name="Fullname" placeholder="Full Name" formControlName="Fullname">
                </div>
                <div class="form-group">
                    <label for="comment">Comment</label>
                    <textarea class="form-control" formControlName="Comment" name="Comment" rows="10" cols="40" ></textarea>
                </div>
                <table>
                    <tr>
                        <td>
                            <button type="submit" class="btn btn-primary" [disabled]="!FormData.valid">Submit</button>
                        </td>
                        <td></td>
                        <td>
                            <button type="button" class="btn btn-primary pull-right" (click)="cancel()">Cancel</button>
                        </td>
                    </tr>
                </table> 
            </form>
        </div>
    </div>
</div>