import { IYearOfNewsletters } from './yearOfNewsletters';
import { INewsletter } from './newsletter';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators'
import { AppConfig } from '../AppConfig/AppConfig';

@Injectable( {
    providedIn: 'root'
})
export class NewsletterService {

    private newsletterUrl = '/Newsletter';
    private newsletterYearsUrl = '/Newsletter/GetYears';
    private newsletterForYearUrl = '/Newsletter/GetByYear?year=';

    constructor(private http: HttpClient) {}

    getNewsletters(): Observable<IYearOfNewsletters[]> {
        const finalUrl = AppConfig.settings.webApi.baseUrl + this.newsletterUrl;
        return this.http.get<IYearOfNewsletters[]>(finalUrl).pipe(
            // tap(data=>console.log('All: ' + JSON.stringify(data))),
            catchError(this.handleError)
        );
    }

    getYears(): Observable<Int32Array> {
        const finalUrl = AppConfig.settings.webApi.baseUrl + this.newsletterYearsUrl;
        return this.http.get<Int32Array>(finalUrl).pipe(
            // tap(data=>console.log('All: ' + JSON.stringify(data))),
            catchError(this.handleError)
        );
    }

    getNewslettersForYear(year: number): Observable<INewsletter[]> {

        const finalUrl = AppConfig.settings.webApi.baseUrl + this.newsletterForYearUrl + year;
        return this.http.get<INewsletter[]>(finalUrl).pipe(
             tap(data=>console.log('Newsletters for " + year + ": ' + JSON.stringify(data))),
            catchError(this.handleError)
        );
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = 'An error occurred: " + err.message}';
        } else {
            errorMessage = 'Server returned code: ' + err.status + ', error message is ' + err.message;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
    
}
