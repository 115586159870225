import { IPassword } from './passwords';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators'
import { AppConfig } from '../AppConfig/AppConfig';

@Injectable( {
    providedIn: 'root'
})
export class PasswordsService {

    constructor(private http: HttpClient) {}
    private getAllPasswordsUrl = '/Passwords/GetAllPasswords';
    private insertOrUpdatePasswordUrl = '/Passwords/InsertOrUpdatePassword';

    getPasswords(): Observable<IPassword[]> {
        const finalUrl = AppConfig.settings.webApi.baseUrl + this.getAllPasswordsUrl;
        return this.http.get<IPassword[]>(finalUrl).pipe(
            // tap(data=>console.log('All: ' + JSON.stringify(data))),
            catchError(this.handleError)
        );
    }

    
    insertOrUpdatePassword(input: any): Observable<boolean> {
   
        const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

        const thisPassword : IPassword = {
            id: input.id,
            year: input.year,
            password: input.password,
            created_at: input.created_at
        };
 
        const finalUrl = AppConfig.settings.webApi.baseUrl + this.insertOrUpdatePasswordUrl;
        return this.http.post<boolean>(finalUrl, JSON.stringify(thisPassword), {headers: headerOptions})
                .pipe(catchError(this.handleError.bind(this)));
    }

 private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = 'An error occurred: " + err.message}';
        } else {
            errorMessage = 'Server returned code: ' + err.status + ', error message is ' + err.message;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
    
}
