import { LoginService } from './login.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})

export class MembersGuard implements CanActivate {
    constructor(private router: Router, private LoginService : LoginService) {}

    isLoggedIn : boolean = false;
    canActivate(next: ActivatedRouteSnapshot): boolean {
        this.LoginService.alreadyloggedIn().subscribe({
            next: success => this.isLoggedIn = success
        });
    
        if (!this.isLoggedIn) {
            this.router.navigate(['login']);
            return false;
        }
        return this.isLoggedIn;
    }
}