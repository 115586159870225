<img src="assets/images/banners/members.jpg" width="620" height="64" alt="Members Only banner">
<br>
<img src="assets/images/pixel.gif" width="1" height="5" alt="">

<div class="center">
    <form #userlogin = "ngForm" (ngSubmit) = "onClickSubmit(userlogin.value)" >
        <input type = "password" name = "passwd" placeholder = "Enter password" ngModel>
        <br/>
        <input type = "submit" value = "submit">
     </form>
</div>
<br/>
<div>
    <p>
    If you are a member in good standing and do not have the password to access
    the Member's Only section, please contact the <a href="mailto:treasurer@stlbrews.org">Treasurer</a>
    at the monthly meeting or via email.
    </p>
</div>