<img src="assets/images/banners/competition.jpg" width="620" height="64" alt="Competition banner">
<br>
<img src="assets/images/pixel.gif" width="1" height="5" alt="">

<div>
    <p>
        Homebrew competitions are a great way for homebrewers to get feedback on their beers from knowledgeable judges.&nbsp; 
        If you&#39;d like to improve your beers and have a bit of a competitive spirit in you, enter a beer or two in a 
        homebrew competition.&nbsp; If you&#39;d like to get involved in the judging of homebrew in a competition, 
        just ask!&nbsp; You don&#39;t have to be an expert to jump in.&nbsp; We were all novices once.&nbsp; To take 
        homebrew beer judging to the next level, check out the <a href="http://www.bjcp.org">Beer Judge Certification Program</a>.
    </p>

    <h4>National Homebrewing Competitions</h4>
    <p>
        Please visit the <a href="http://www.bjcp.org/apps/comp_schedule/competition_schedule.php">American Homebrewers Association</a> 
        website for the most up-to-date list of homebrew competitions on the web.
    </p>

    <h4>Local Homebrewing Competitions</h4>
<div *ngIf="upcomingCompetitions; else elseBlock">
    <p>
        Below are local competitions sponsored by the St. Louis Brews:
    </p>
    <br/>
    <div *ngFor='let comp of upcomingCompetitions'>
        <br />
        <h1 class="center"><u>{{comp.competition_name}}</u></h1>
        <h2 class="center"><u>{{comp.competition_date | date: 'longDate'}}</u></h2>
        <p class="center">
            <a href={{comp.more_info}}>
                <img alt="Homebrew Competition" border="0" src={{comp.logo}}  height="200" /></a>
        </p>
        <p class="center">
            Registration for entries and judging/stewarding will begin on 
            {{comp.registration_begin_date | date:'longDate'}}, and will close on
            {{comp.registration_end_date | date:'longDate'}}
        </p>
        <p class="center">
            <a href={{comp.more_info}}>Click here</a> for more information about the competition.
        </p>
        <br/>
        <hr width="90%">
    </div>
</div>
<ng-template #elseBlock>
    <i>Sorry - no competitions are scheduled at this time.</i>
    <hr width="90%">
</ng-template>

  
<h4>Previous Winners</h4>
<div>
    <table width=600>
        <tr>
            <td valign="top" align="center">
                <table>
                    <thead>
                        <th>HHHC</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let comp of hhhcWinners">
                            <td>
                                <a href={{comp.file_name}}>{{comp.competition_year}}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
            <td valign="top" align="center">
                <table>
                    <thead>
                        <th>Microfest</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let comp of microfestWinners">
                            <td>
                                <a href={{comp.file_name}}>{{comp.competition_year}}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
            <td valign="top" align="center">
                <table>
                    <thead>
                        <th>VirtuAle</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let comp of virtualeWinners">
                            <td>
                                <a href={{comp.file_name}}>{{comp.competition_year}}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </table>
</div>


