import { Component } from '@angular/core';

@Component({
  templateUrl: './supporters.component.html',
  styleUrls: ['./supporters.component.css']
  
})
export class SupportersComponent {
  public pageTitle = 'Supporters';
}
