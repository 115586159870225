import { IEmailContent } from './../Email/emailContent';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { AppConfig } from '../AppConfig/AppConfig';

@Injectable( {
    providedIn: 'root'
})
export class LoginService {

    private loginUrl = '/login?password=';

    private sessionVariableName = "loginExpirationTime";

    public retPostData

    constructor(private http: HttpClient) {
    }

    tryLogin(password : string): Observable<boolean> {

 
        const finalUrl = AppConfig.settings.webApi.baseUrl + this.loginUrl + password;
        return this.http.get<boolean>(finalUrl).pipe(
            // tap(data=>console.log('TryLogin JSON: ' + JSON.stringify(data))),
            catchError(this.handleError) 
        );
    }


    alreadyloggedIn() : Observable<boolean> {

        const expirationTime = localStorage.getItem(this.sessionVariableName);

        if (expirationTime === null) {
            return of(false);
        }

        const now = new Date(Date.now());
        const loginExpiration = new Date(expirationTime);
 
        if (now > loginExpiration)
            return of(false);

        this.updateExpirationTime();
            
        return of(true);
    }

    updateExpirationTime() {
        const now = new Date(Date.now());
        const newExpirationTime =  new Date(now.getTime() + (1000 * 60 * 60));
        localStorage.setItem(this.sessionVariableName, newExpirationTime.toString());
        return;
    }


    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = 'An error occurred: " + err.message}';
        } else {
            errorMessage = 'Server returned code: ' + err.status + ', error message is ' + err.message;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }

}




   

