import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AdminLoginService } from './adminlogin.service';


@Component({
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./admin.component.css'],
  providers: [AdminLoginService]
})


export class AdminLoginComponent {

 
    constructor(private router: Router, private AdminloginService: AdminLoginService) {}


    ngOnInit() : void {
        this.AdminloginService.alreadyloggedIn().subscribe({
            next: success => {
                if (success) {
                    this.router.navigate(['/admin']);
                }
            }
        });
     }


    onClickSubmit(data) {
        this.AdminloginService.tryLogin(data.passwd).subscribe({
            next: success => {
                if (success) {
                    this.AdminloginService.updateExpirationTime();
                    this.router.navigate(['/admin']);
                }
                else {
                    alert('Incorrect password entered');
                    window.location.reload();
                }
            }
        });
 
    }

}


