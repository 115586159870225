<img src="assets/images/pixel.gif" width="1" height="5" alt="">

<div class="center">
    <form #userlogin = "ngForm" (ngSubmit) = "onClickSubmit(userlogin.value)" >
        <input type = "password" name = "passwd" placeholder = "Enter password" ngModel>
        <br/>
        <input type = "submit" value = "submit">
     </form>
</div>
<br/>
