<img src="assets/images/banners/about.jpg" width="620" height="64" alt="About Us banner">
<br>
<img src="assets/images/pixel.gif" width="1" height="5" alt="">

<div>
    <p class="center">
        <img alt="Typical Brews Meeting" width="500" src="assets/images/meeting-last.jpg" />
    </p>
    <p class="img-desc">
        A typical St. Louis Brews meeting.
    </p>
    <br />
    <h4>When &amp; Where We Meet</h4>
    <p>
        We meet on the first Thursday of every month at <a href="https://www.mknzbrewing.com/"><b>Mackenzie Brewing
            </b></a>, located at 932 Meramec Station Rd, Valley Park, 63088. Directions can be found
        <a
            href="https://www.google.com/maps/place/Mackenzie+Brewing+Company/@38.5366586,-90.4975327,19.04z/data=!4m15!1m8!3m7!1s0x87d8d10ffdf4174f:0x9dc7be1cf156f58e!2s932+Meramec+Station+Rd,+Valley+Park,+MO+63088!3b1!8m2!3d38.5367268!4d-90.4973422!16s%2Fg%2F11bw3yk94w!3m5!1s0x87d8d10ffe421b99:0xb500eba328515e4a!8m2!3d38.5368775!4d-90.4973424!16s%2Fg%2F11f4qy7tx2?entry=ttu">HERE</a>.
        It is roughy located at I-44 and 141, and is in the same plaza as Sugarfire BBQ.
        The meetings begin at 7:00pm and usually
        finish around 10:00pm. We do allow visitors to join our meetings for a couple of times before we ask them to
        join.
    </p>

    <app-officers></app-officers>
    <p>
        <b>The St. Louis Brews is a club dedicated to the brewing, evaluation, and responsible consumption of homebrewed
            beers.

        </b>
    </p>
    <br />
    <h4>What We Do</h4>
    <p>
        We focus on a particular style, or styles for a given meeting. Please see the monthly meeting schedule on the
        home page.&nbsp;
        Our members bring beers of that style, and we evaluate and compare against commercial examples. In most cases
        our members&#39; homebrewed beers are better than the commercial examples!
        If you would like to bring a beer for evaluation at a meeting, we normally ask you to bring at least (6) 12oz.
        bottles or an equivalent amount of draft beer from a keg (1 growler).&nbsp;

        Please let our <a href="contactus/Braumeister/about">Brewmeister</a> know if you are bringing beer so we know
        how much to expect.
    </p>

    <h4>Is There a Cost?</h4>
    <p>
        Membership is currently $35.00 per calendar year. If are a new member and join after July 1, the
        membership fee is $17.50. There is also a meeting fee of $5. This helps cover the cost of food and commercial
        examples of beer. If you bring
        beer or food to the meeting, the meeting fee is waived.
    </p>
    <p>
        When you become a member you receive a monthly newsletter,
        invitations to our two picnics, our National Homebrew Day celebration, and our end of year banquet, not to
        mention special events that come along such as brewery tours,
        meet &amp; greets with brewing illuminati, beer tastings, etc. You also get a membership card that entitles you
        to special benefits around town,
        listed on our <a href="/supporters">Friends of the Brews</a> page. Want to give us a test drive before
        joining?&nbsp;
        Come as a guest a few times.&nbsp;

        <b> Membership in the St. Louis Brews and
            attendance at our meetings is restricted to those 21 years of age and older.</b>
    </p>
    <p>
        <!--We have set up a PayPal account to allow new and existing members to pay their membership dues. <b>NOTE: </b>You will notice
        three buttons below.  Unfortunately, PayPal changed their interface and it now provides these three buttons.  Because we are currently
        on a non-SSL server, the use of the last button ("Debit or Credit Card") is <b>NOT</b> recommended.-->
        <!--Additionally, you can pre-pay your $5.00 meeting fee. You will be automatically entered twice into the drawing, and can save the time in not needing to check in at the meeting!-->
    </p>

    <div #paypalRef></div>

    <br />
    <h4>
        <a href="http://www.homebrewersassociation.org/pages/grow-your-business/banner-ads/st-louis-brews">American
            Homebrewers Association
            Signup</a>
    </h4>
    <p>
        Interested in joining the AHA?&nbsp; Start or renew your membership through the
        link below and the club gets a $5 bonus! Its a great way to help your club out
        while helping yourself with a great AHA membertship.
    </p>
    <p>
        <a href="http://www.homebrewersassociation.org/pages/grow-your-business/banner-ads/st-louis-brews"><img
                style="padding: 0pt;" src="assets/images/AHA180x150.gif" alt="AHA" /></a>
    </p>
    <br />

    <br />
    <p class="center">
        We all have a great time. Hope to see you there!
    </p>

    <div class="center">
        <img alt="bottle animation" height="245" src="assets/images/bottle1.gif" width="77" />
    </div>


</div>