<img src="assets/images/banners/members.jpg" width="620" height="64" alt="Members Only banner">
<br>
<img src="assets/images/pixel.gif" width="1" height="5" alt="">

<div>
    <table border="0" width=620 cellpadding="0" cellspacing="0">
        <tr>
            <td colspan=1 width=600 valign="top" align="left">
                <h4>
                   Welcome to the members-only section.
                </h4>
                <p>
                    There's beer in the fridge. Help yourself.
                </p>
                <br/>
            
                <h4>
                    <a href="contactus/Braumeister/members">Notify Brewmeister</a>
                </h4>
                <p>
                We want it to be <i><font size="+1">EASY</font></i> for you to 
                bring beers to the meeting. This page allows you to notify the 
                brewmeister that you are bringing beer to the next meeting.
                </p>
                <br/>
                
                <h4>
                    <a href="newsletter">Newsletters</a>
                </h4>
                <p>
                Brews newsletters are posted in the members-only section for two reasons:
                </p>
                <ol>
                    <li>It's a benefit of being a paid member
                    <li>Two words "copyright violation"
                </ol>
                <br/>

                <!-- <h4>
                    Bottle Cap Label Template
                </h4>
                <p>
                    <a href="/members/BottleCapLabels.doc">Right-click here</a> and choose Save 
                    As... to download the MS Word template for the 0.75&quot; circle label, 108 
                    labels/sheet.
                </p>
                <p>
                    Additionally, <a href="http://www.onlinelabels.com/Templates/OL5275.html">here 
                    is a link</a> to the website of the label manufacturer, which supplies other 
                    template formats for this label.
                </p>
                <br/>

                <h4>
                    <a href="/members/hhhcpromo.asp">Happy Holiday Homebrew Competition 
                    Promotional Materials</a>
                </h4>
                <br />	                 -->

                <h4>
                    <a href="http://www.homebrewersassociation.org/pages/grow-your-business/banner-ads/st-louis-brews">American Homebrewers Association 
                    Signup</a>
                </h4>
                <p>
                    Interested in joining the AHA?&nbsp; Start or renew your membership through the 
                    link below and the club gets a $5 bonus!  Its a great way to help your club out
                    while helping yourself with a great AHA membertship.
                </p>
                <p>
                    <a href="http://www.homebrewersassociation.org/pages/grow-your-business/banner-ads/st-louis-brews"><img style="padding: 0pt;" src="assets/images/AHA180x150.gif" alt="AHA" /></a>
                </p>
                <br />	
                            
                <h4>
                    <a href="assets/Members/St%20Louis%20Brews%20By-Laws.pdf">By-Laws
                    Download</a>
                </h4>
                <p>
                    Right-click on the link and choose &quot;Save Target as...&quot; to save the .PDF
                    file to your system.&nbsp; Find the file and double-click it to open the
                    document.&nbsp; If you do not have the Adobe Acrobat Reader software, you can
                    download it here.&nbsp; <a href="http://www.adobe.com/products/acrobat/readstep2.html">Adobe
                    Acrobat Reader</a>
                </p>
            </td>
        </tr>
    </table>
 
 </div>