import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';


@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./members.component.css'],
  providers: [LoginService]
})


export class LoginComponent {

 
    constructor(private router: Router, private loginService: LoginService) {}


    ngOnInit() : void {
        this.loginService.alreadyloggedIn().subscribe({
            next: success => {
                if (success) {
                    this.router.navigate(['/members']);
                }
            }
        });
     }


    onClickSubmit(data) {
        this.loginService.tryLogin(data.passwd).subscribe({
            next: success => {
                if (success) {
                    this.loginService.updateExpirationTime();
                    this.router.navigate(['/members']);
                }
                else {
                    alert('Incorrect password entered');
                    window.location.reload();
                }
            }
        });
 
    }

}


