import { IPassword } from './passwords';
import { PasswordsService } from './passwords.service';
import { Component } from '@angular/core';
import { expressionType } from "@angular/compiler/src/output/output_ast";
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

imports: [CommonModule]

@Component({
  templateUrl: './passwords.component.html',
  styleUrls: ['../app.component.css', './passwords.component.css']
})
export class AdminPasswordsComponent {
    public pageTitle = 'Passwords';
    public allPasswords : IPassword[];
    errorMessage : String;
    years : Int32Array;

    public year: number;
    public password: string;

    FormData: FormGroup;

    form = new FormGroup({

        Years: new FormControl('', Validators.required)
    
      });
    
      
    constructor(private builder: FormBuilder, private passwordsService: PasswordsService) {
    }
   
    ngOnInit() : void {
       
        this.passwordsService.getPasswords().subscribe({
            next: passwords => this.allPasswords = passwords,
            error: err => this.errorMessage  = err
        });

      }

    submit(){

        console.log(this.form.value);
        console.log("year=", this.year);
        console.log("password=", this.password);
        
      }
    
      
    
//      changeYear(e) {
//    
//        console.log(e.target.value);
//        this.newsletterService.getNewslettersForYear(e.target.value).subscribe({
//            next: newsletters => this.thisYearsNewsletters = newsletters,
//            error: err => this.errorMessage  = err
//        });
//     }
    
          

 
}