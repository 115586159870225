<img src="assets/images/banners/home.jpg" width="620" height="64" alt="home banner">
<br>
<img src="assets/images/pixel.gif" width="1" height="5" alt="">

<div>
    <h3 class="center">
        <b>Welcome to the St. Louis Brews Homebrew Club!</b>
    </h3>
    <p class="center">
        <img alt="Typical Brews Meeting" width="500" src="assets/images/meeting.jpg" />
    </p>
    <br />
    <h4>About Us</h4>
        <p>
            The St. Louis Brews Homebrew Club is the oldest and largest club in the St. Louis area.  Our focus is towards education of beer styles and homebrewing
            techniques.  At each meeting, we focus on a small number of styles.  Members bring beers of that style, and we evaluate and compare them, giving the brewers
            plenty of feedback on how to make their beers even better.  We also try to have a tech topic at each meeting where we discuss brewing equipment and procedures.
        </p>
        <p>
        We meet on the first Thursday of every month at <a href="https://www.mknzbrewing.com/"><b>Mackenzie Brewing
        </b></a>, located at 932 Meramec Station Rd, Valley Park, 63088.  Directions can be found 
        <a href="https://www.google.com/maps/place/Mackenzie+Brewing+Company/@38.5366586,-90.4975327,19.04z/data=!4m15!1m8!3m7!1s0x87d8d10ffdf4174f:0x9dc7be1cf156f58e!2s932+Meramec+Station+Rd,+Valley+Park,+MO+63088!3b1!8m2!3d38.5367268!4d-90.4973422!16s%2Fg%2F11bw3yk94w!3m5!1s0x87d8d10ffe421b99:0xb500eba328515e4a!8m2!3d38.5368775!4d-90.4973424!16s%2Fg%2F11f4qy7tx2?entry=ttu">HERE</a>. 
    It is roughy located at I-44 and 141, and is in the same plaza as Sugarfire BBQ. 
             The meetings begin at 7:00pm and usually
        finish around 10:00pm.  We do allow visitors to join our meetings for a couple of times before we ask them to join.
    </p>
    <h4>Benefits of Membership</h4>
        <p>
            So what does joining the Brews get you?  Well, in addition to all of the fun and comradery of our monthly meetings, you get:
        </p>
        <ul>
            <li>Monthly Newsletter - Features club news, calendar of events, beer style discussion, and a tech topic.</li>
            <li>Spring and Fall Picnic - We have club picnics in the spring and fall for our members. The club provides the main course and members bring side dishes.</li>
            <li>Friendship Brews - We randomly match up brewers to brew together on a defined style.  The groups compete their beers against each other at the picnics.</li>
            <li>
                Holiday Banquet - Our HHHC banquet and annual homebrew competition.  The club provides a nice main course (usually prime rib and other meats), and
                members bring side dishes.  Competition awards are given out at the banquet, and the kids even get a visit from Santa!
            </li>
            <li>Jockey Box Usage - The club owns four jockey boxes, and members are free to use them whenever they choose.</li>
            <li>Firkin Usage - The club owns two ten-gallon firkins, and members are free to use them whenever they choose.</li>
            <li>Discounts - A number of area breweries, bars, and homebrew supply stores generously afford our members discounts.  
                Please visit our <a href="/supporters">Supporters</a> page for a list.
            </li>
        </ul>

    <app-home-meeting></app-home-meeting>

    <h4>Membership Dues</h4>
    <p>
        Our membership dues are $35 for a full calendar year.  Please visit the About Us page to pay with PayPal or
        credit card.  
    </p>

    <app-home-events></app-home-events>
    
    <br />
    <br />
    <h4><b>Check Us Out!</b></h4>
    <table>
        <tr>
            <td class="social-img">
                <a href="https://www.facebook.com/groups/110584219030433/" target="_blank">
                    <img src="assets/images/fb-art.png" alt="facebook" />
                </a>
            </td>
            <td>
                <span class="social-text">
                    Checkout our <a href="https://www.facebook.com/groups/110584219030433/" target="_blank">Facebook Group</a>
                    and connect with our local and extended group of homebrewers.  We try to keep it regularly updated with news of upcoming events, topics,
                    and general brewing information.
                </span>
            </td>
        </tr>
        <tr>
            <td class="social-img">
	            <a href="https://twitter.com/stlbrews" target="_blank">
		            <img  src="assets/images/Twitter-Download-PNG.png" alt="twitter" />
	            </a>
            </td>
            <td>
	            <span class="social-text">
		            Follow the <a href="https://twitter.com/stlbrews" target="_blank">St. Louis Brews</a> on twitter.
	            </span>
            </td>
        </tr>
    </table>
    <br />
    <br />
    <p>
        Brews Monthly Styles---
        <!-- <a href="assets/Other/schedule2021.htm"  target="_blank">HTML</a>--- -->
        <a href="assets/other/schedule2021.pdf"  target="_blank">PDF</a>
    </p>
    <p>
        2024 Brews Monthly Styles---
        <!-- <a href="assets/Other/schedule2021.htm"  target="_blank">HTML</a>--- -->
        <a href="assets/other/schedule2024.pdf"  target="_blank">PDF</a>
    </p>
    <br />
    <br />

</div>
