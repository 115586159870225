<img src="assets/images/banners/competition.jpg" width="620" height="64" alt="Competition banner">
<br>
<img src="assets/images/pixel.gif" width="1" height="5" alt="">

<div class="image"><img src="assets/images/logos/MeadMe.jpg" width="300" height="300" alt="Competition banner"></div>
<div>
    <p>
        <b>"Mead Me In St. Louis (And Cider Me Too)"</b> (or just "MeadMe" for short) is a new competition spun off from
        the Happy
        Holiday Homebrew Competition (HHHC) held in December. We will still have a beer-only HHHC, but meads and ciders
        will be
        judged at MeadMe instead.
    </p>
    <p>
        Why would be want to have a separate competition for meads and ciders? Several reasons. One of the biggest is
        that with all of
        the certified mead and certified cider judges in the area, we really wanted a separate competition so that they
        could concentrate on meads and ciders. The advantage to entrants is that now you get a competition
        where your
        beverages will be judged by people that specialize in those areas. September also happens to be National Honey
        Month, so what better time that that?
    </p>
    <p>
        We will have custom designed medals for the winners in each category, and mead/cider best of show winners will 
        also get a beautiful hand-made wooden plaque. Winners from past HHHC competitions know how nice these are. And
        as an added bonus, the National Honey Board has gratiously contributed fantastic honey prizes for mead winners.
    </p>
    <p>
        MeadMe is scheduled for Saturday, September 7, 2024 at
        <a href="https://www.alphabrewingcompany.com">Alpha Brewing Company</a>, located at 4310 Fyler Avenue, St.Louis
        MO 63116. It is possible
        we may need a pre-judging session, in which case that would be on Friday, September 6. </p>
    <p>
        More details and rules may be found at the <a href = "https://stlbrews.brewingcompetitions.com">competition web site</a> beginning in May.
        But now is the time to start making that award winning meads and ciders.  We will be capping the competition at 250 entries and expect to
        get close to that.  This should be a great competition to show off your mead and cider making skills!
    </p>
</div>