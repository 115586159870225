<h4>Other Upcoming Events</h4>
<p>
Below are just some of the events upcoming in the next few months.  Please visit our <a href="http://www.stlbrews.org/calendar">Calendar</a> page for much more information.</p>
    <div *ngIf="currentEvents; else elseBlock">
        <ul *ngFor='let event of currentEvents'>
            <li>
                <b>{{event.event_date | date:'MM/dd/yyyy'}}</b>&nbsp;&nbsp;<u>{{event.event_name}}</u> - {{event.event_description}} 
                <a href="{{event.url}}">{{event.url}}</a>
            </li>
        </ul>
    </div>
    <ng-template #elseBlock><i>Sorry - no events on the schedule at this time</i></ng-template>
