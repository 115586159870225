import { Component } from "@angular/core";
import { EmailService } from "../Email/email.service";
import { IEmailContact } from "../Email/emailContact";

@Component({
    selector: 'app-officers',
    templateUrl: './officers.component.html',
  })
export class OfficersComponent {
    recipients : IEmailContact[];
    errorMessage : String;

    constructor(private emailService: EmailService) {
    }
   
    ngOnInit() : void {
       
        this.emailService.getAllRecipients().subscribe({
            next: officers => this.recipients = officers
          });
    }

}
