import { IEmailContact } from './emailContact';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from './email.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {

  FormData: FormGroup;
  DefaultRecipient: string;
  From: string

  public recipients: IEmailContact[];

  constructor(private builder: FormBuilder, private emailService: EmailService, private router: Router,
            private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.FormData = this.builder.group({
        Fullname: new FormControl('', [Validators.required]),
        Email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
        Comment: new FormControl('', [Validators.required]),
        Recipient: new FormControl('', [Validators.required])});
      this.DefaultRecipient=this.route.snapshot.paramMap.get("recipient");
      this.From=this.route.snapshot.paramMap.get("from");

      this.emailService.getAllRecipients().subscribe({
        next: officers => this.recipients = officers
      });
}

  onSubmit(FormData) {
    this.emailService.PostMessage(FormData)
      .subscribe(response=> {
        this.router.navigate([this.From]);
        },
        error => {
          alert("Error occurred.  Email not sent.")
        })
  }

  cancel() {
    this.router.navigate([this.From]);
  }

}
