<img src="assets/images/banners/members.jpg" width="620" height="64" alt="Members Only banner">
<br>
<img src="assets/images/pixel.gif" width="1" height="5" alt="">

<div>
    <div align="center"><font size="+1">Brews Newsletters</font></div>

    <p>The latest copies of the newsletters will be posted here as well as older ones as we can get them ready.</p>
    
    <br />

    <table border="0" width=620 cellpadding="0" cellspacing="0">
        <tr>
            <td colspan=1 width=600 valign="top" align="left">
                <img src="assets/images/misc/keg.gif" alt="Beer Keg" width=198 height=291 align=left>
            </td>
            <td colspan=1 width=20 ></td>
            <td colspan-1 width=300 valign="top" align="left">
                <!-- <div *ngIf="allNewsletters"> -->
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="form-group">
                            <label for="Years">Year:</label>
                            <select formControlName="Years" class="form-control" (change)="changeYear($event)">
                                <option disabled>Select Year</option>
                                <option>Choose Year</option>
                                <option *ngFor="let year of years">{{year}}</option>
                            </select>
                        </div>
                    </form>
                
                <div *ngFor="let newsletter of thisYearsNewsletters">
                    <div *ngIf="newsletter.link; else elseBlock">
                        <a href="{{newsletter.link}}">{{newsletter.name}}</a>
                    </div>
                    <ng-template #elseBlock>
                        {{newsletter.name}}
                    </ng-template>
                </div>
                <br />
            </td>
        </tr>
    </table>
</div>