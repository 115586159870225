import { AdminLoginService } from './adminlogin.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})

export class AdminGuard implements CanActivate {
    constructor(private router: Router, private AdminLoginService : AdminLoginService) {}

    isLoggedIn : boolean = false;
    canActivate(next: ActivatedRouteSnapshot): boolean {
        this.AdminLoginService.alreadyloggedIn().subscribe({
            next: success => this.isLoggedIn = success
        });
    
        if (!this.isLoggedIn) {
            this.router.navigate(['adminlogin']);
            return false;
        }
        return this.isLoggedIn;
    }
}