  <table border="0" cellpadding=0 cellspacing=0 width=760>
    <tr>
      <td colspan=1 width=140 valign=top align=left class="grains">
        <app-leftNav></app-leftNav>
      </td>
      
      <td colspan=2 width=620 valign=top align=left>
          <table border="0" cellpadding=0 cellspacing=0 width=620>
            <tr>
              <td>
                <div class='container'>
                  <router-outlet></router-outlet>
                </div>
              </td>
            </tr>
      </table>
      </td>
    </tr>
    <tr>
      <td colspan=2 width=760 valign=top align=center>
        <app-footer></app-footer>
      </td>
    </tr>
  </table>


  