import { Component, ElementRef, ViewChild } from '@angular/core';
import { OfficersComponent } from './officers.component';

@Component({
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
  
})
export class AboutComponent {
  public pageTitle = 'About Us';

  constructor() {
  }

  @ViewChild('paypalRef', {static: true}) private paypalRef: ElementRef;
  ngOnInit():void {
    window.paypal.Buttons(
      {
        style: {
          layout: 'vertical',
          color: 'gold',
          shape: 'pill',
          label: 'paypal',
          size: 'small',
          height: 40,
          tagline: 'false'
        },
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: 36.80,
                  currency_code: 'USD'
                }
              }
            ]
          })
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then(details => {
            alert('Transaction completed');
          })
        },
        onError: error => {
          console.log(error);
        }
      }
    ).render(this.paypalRef.nativeElement);
  }

}
