import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators'

import { IWinner } from './winner';
import { AppConfig } from '../AppConfig/AppConfig';

@Injectable( {
    providedIn: 'root'
})
export class HHHCWinnerService {

    private winnerUrl = '/WinnerSheet/GetByCompetition/HHHC';
 
    constructor(private http: HttpClient) {}

    getWinners(): Observable<IWinner[]> {
        const finalUrl = AppConfig.settings.webApi.baseUrl + this.winnerUrl;
        return this.http.get<IWinner[]>(finalUrl).pipe(
            // tap(data=>console.log('All: ' + JSON.stringify(data))),
            catchError(this.handleError)
        );
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = 'An error occurred: ${err.error.message}';
        } else {
            errorMessage = 'Server returned code: ${err.status}, error message isL ${err.message}';
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
    
}
