import { Component } from '@angular/core';
import { IWinner } from "./winner";
import { HHHCWinnerService } from './hhhc.winner.service'
import { MicrofestWinnerService } from './microfest.winner.service'
import { VirtuAleWinnerService } from './virtuale.winner.service'
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { ICompetition } from './competition';
import { CompetitionService } from './competitions.service';

  
@Component({
  templateUrl: './competitions.component.html',
  styleUrls: ['../app.component.css','./competitions.component.css']
  
})
export class CompetitionsComponent {
    hhhcWinners : IWinner[];
    microfestWinners : IWinner[];
    virtualeWinners : IWinner[];

    errorMessage : String;

    upcomingCompetitions : ICompetition[];

    selectedHHHC : string;
    selectedHHHCFileName : string;
    selectedHHHCYear : string;

    selectedMicrofest : string;
    selectedMicrofestFileName : string;
    selectedMicrofestYear : string;

    selectedVirtuAle : string;
    selectedVirtuAleFileName : string;
    selectedVirtuAleYear : string;

    constructor(private hhhcWinnerService: HHHCWinnerService, 
        private microfestWinnerService: MicrofestWinnerService, 
        private virtualeWinnerService: VirtuAleWinnerService, 
        private competitionService: CompetitionService) {
              }
   
    form = new FormGroup({
        website: new FormControl('', Validators.required)
      });
    get f(){
        return this.form.controls;
    }

    
    ngOnInit() : void {
      this.hhhcWinnerService.getWinners().subscribe({
              next: winners => this.hhhcWinners = this.SortWinners(winners),
              error: err => this.errorMessage  = err
          });
    
        this.microfestWinnerService.getWinners().subscribe({
            next: winners => this.microfestWinners = this.SortWinners(winners),
            error: err => this.errorMessage  = err
        });

        this.virtualeWinnerService.getWinners().subscribe({
            next: winners => this.virtualeWinners = this.SortWinners(winners),
            error: err => this.errorMessage  = err
        });

        this.competitionService.getCompetitions().subscribe({
            next: comps => this.upcomingCompetitions = this.FilterAndSort(comps),
            error: err => this.errorMessage  = err
        });
    }

    private FilterAndSort(competitions: ICompetition[]) : ICompetition[] {
        competitions = competitions.filter(function(obj) {
            return new Date(obj.start_date) <= new Date() && new Date(obj.end_date) >= new Date();
          });
        competitions.sort((a,b) => (new Date(a.competition_date) > new Date(b.competition_date)) ? 1 : -1);
        if (competitions.length < 1)
            return null;
        else
            return competitions;
      }

      private SortWinners(winners: IWinner[]) : IWinner[] {
        winners.sort((a,b) => ( a.competition_year < b.competition_year) ? 1 : -1);
        if (winners.length < 1)
            return null;
        else
            return winners;
      }


}
