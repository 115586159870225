<img src="assets/images/banners/links.jpg" width="620" height="64" alt="Links banner">
<br>
<img src="assets/images/pixel.gif" width="1" height="5" alt="">

<div>
    <a name="top"> </a><br>
    <h2>Table of Contents</h2>
    <p>
        This page contains links to web sites and pages having
        to do with beer and brewing. The information is divided
        into the following categories:
    </p>
    <ul>
        <li><a href="#cat1"><strong>Homebrew Info</strong></a></li>
        <li><a href="#shops"><strong>Local Homebrew Supply Shops</strong></a></li>
        <li><a href="#shops2"><strong>National Homebrew Supply Shops</strong></a></li>
        <li><a href="#cat2"><strong>Homebrew Clubs</strong></a></li>
        <li><a href="#cat7"><strong>Local Breweries, Brewpubs</strong></a></li>
        <li><a href="#cat3"><strong>Large Commercial Breweries, Brewpubs</strong></a></li>
        <li><a href="#cat4"><strong>Beer Magazines</strong></a></li>
        <li><a href="#cat5"><strong>Other Beer Stuff</strong></a></li>
        <li><a href="#cat6"><strong>Member's Pages</strong></a></li>            
    </ul>
    <p>
        If you have any links you would like to see listed
        here, send them to <a href="mailto:webmeister@stlbrews.org">webmeister@stlbrews.org</a>.
    </p>
    <hr width="90%">

    <h3><a name="cat1">Homebrew Info</a></h3>
    <p>
        The following are excellent resources for homebrewers.
    </p>
    <ul>
        <li><a href="http://www.howtobrew.com/">How To Brew - John Palmer</a></li>
        <li><a href="http://www.homebrewersassociation.org/">American Homebrewers Association</a></li>
        <li><a href="http://www.brewersassociation.org">Brewers Association</a></li>
        <li><a href="http://byo.com/stories/wizard">Brew Wizard Home Page</a></li>
        <li><a href="http://www.uvm.edu/~pass/perry/hops.html">Hops Information</a>
        <li><a href="http://www.siebelinstitute.com/">Siebel Institute of Technology</a></li>
        <li><a href="http://www.amwater.com/moaw/">St. Louis County Water Company (Missouri American Water)</a></li>
        <li><a href="http://www.asbcnet.org/">The American Society Of Brewing Chemists</a></li>
        <li><a href="http://www.beerinfo.com/">The Beer Info Source</a></li>
        <li><a href="http://www.beerinstitute.org/">The Beer Institute</a></li>
        <li><a href="http://beerstreetjournal.com/">The Beer Street Journal</a></li>
        <li><a href="http://www.bjcp.org/">BJCP - The Beer Judge Certification Program</a></li>
        <li><a href="http://masterhomebrewer.org/">MCAB - Masters Championship of Amateur Brewing</a></li>
        <li><a href="http://www.mrmalty.com/">Mr. Malty (Jamil Z.) - Tools and info</a></li>
        <li><a href="http://www.realbeer.com/hops/">The Hop Page</a></li>
        <li><a href="http://www.tobp.com">The Opinionated Beer Page</a></li>
        <li><a href="http://www.realbeer.com/">The Real Beer Page</a></li>
        <li><a href="http://www.wyeastlab.com/">Wyeast Information on Liquid Yeast</a></li>
        <li><a href="http://www.whitelabs.com/">White Labs Liquid Yeast</a></li>
        <li><a href="http://www.omegayeast.com/homebrew/">Omega Yeast</a></li>
        <li><a href="http://mbhp.forgottensea.org/thebrewer.html">"The Brewer", by William Loftus, published in 1863</a></li>
        <li><a href="http://www.levity.com/alchemy/jfren_ar.html">John French's Art of Distillation (1651)</a></li>
        <li><a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=2&cad=rja&uact=8&ved=0ahUKEwjjhOjZu6_UAhWBOD4KHYRuANYQFggvMAE&url=https%3A%2F%2Farchive.org%2Fdetails%2Famericanhandybo00wahlgoog&usg=AFQjCNEQX-2O1xWpBg2piE5LAvVzedVgNA">American Handy Book - Wahl & Henius</a></li>
    </ul>
    <h5><a href="#top">Back to Top</a></h5>
    <hr width="90%">

    <h3><a name="shops">St. Louis Area Homebrew Supply Shops</a></h3>
    <ul>
        <li><a href="http://www.wineandbeermaking.com">St. Louis Wine and Beermaking</a></li>
        <li><a href="http://www.momalt.com">Missouri Malt Supply</a></li>
        <li><a href="http://www.happyhophomebrew.com/">Happy Hop Homebrew</a></li>
        <li><a href="http://www.Brewandwinesupply.com">Brew and Wine Supply - Hillsboro</a></li>
        <li><a href="http://www.design2brew.com">Design2Brew</a></li>
        <li><a href="http://ww3.truevalue.com/brewerstruevalue/home.aspx">Brewer's True Value</a></li>
        <li><a href="https://www.facebook.com/Green-Thumb-Organics-1169187776432147/">Green Thumb Organics</a></li>
	</ul>
    <h5><a href="#top">Back to Top</a></h5>
    <hr width="90%">

    <h3><a name="shops2">National/Mail Order Homebrew Supply Shops</a></h3>
    <ul>
        <li><a href="http://www.williamsbrewing.com">William's Brewing</a></li>
        <li><a href="http://www.northernbrewer.com">Northern Brewer Homebrew Supply</a></li>
        <li><a href="http://www.midwestsupplies.com">Midwest Homebrew Supplies</a></li>
        <li><a href="http://www.morebeer.com">Beer, Beer and More Beer</a></li>
        <li><a href="http://www.homebrewsupply.com">Homebrew Supply</a></li>
        <li><a href="http://www.homebrewstuff.com">Homebrew Stuff</a></li>li>
        <li><a href="http://www.strangebrew.net">StrangeBrew</a></li>
        <li><a href="http://www.rebelbrewer.com">Rebel Brewer</a></li>
        <li><a href="http://www.hoptech.com">HopTech Homebrew Supplies</a></li>
	</ul>
    <h5><a href="#top">Back to Top</a></h5>
    <hr width="90%">

    <h3><a name="cat2">Homebrew Clubs</a></h3>
    <p>
        These are some other homebrew clubs that are on the
        web.
    </p>
    <ul>
        <li><a href="http://www.garagebrewers.com/">Garage Brewers Society</a></li>
        <li><a href="http://www.buzzbrewclub.org/">Boneyard Union of Zymurgical Zealots</a></li>
        <li><a href="http://chibeer.org/">Chicago Beer Society</a></li>
        <li><a href="http://www.kcbiermeisters.org/">Kansas City Bier Meisters</a></li>
        <li><a href="http://prairiehomebrewers.org/">Prairie Homebrewing Companions</a></li>
        <li><a href="http://www.jchomebrewer.com/">Jefferson  County Home Brewers</a></li>
        <li><a href="https://sites.google.com/site/ogwomen/">The OG St. Louis Women's Craft Beer Collective</a></li>
        <li><a href="http://stlhops.com/forum/viewtopic.php?f=8&t=5023">STL Hops Homebrew Club</a></li>
        <li><a href="http://www.theworts.com/">The Wort's Homebrew Club</a></li>
        <li><a href="http://stcbrews.com/">St. Charles Brew Club</a></li>
        <li><a href="http://mhva.org/">Missouri Home Vintner's Association</a></li>
        <li><a href="http://stlbrewminati.com/">Brewminati Homebrew Club</a></li>
    </ul>
    <h5><a href="#top">Back to Top</a></h5>
    <hr width="90%">

    <h3><a name="cat5">Other Beer Stuff</a></h3>
    <p>
        If its about beer, and not in a category above, then its here.
    </p>
    <ul>
        <li><a href="http://www.beersteinsinternational.com">Beer Steins International</a> - Beer Steins International offers a wide selection of authentic German beer steins and beer mugs.</li>
        <li><a href="http://www.bcca.com/">Brewery Collectibles Club of America</a></li>
        <li><a href="http://www.camra.org.uk/">CAMRA - Campaign For Real Ale</a></li>
        <li><a href="http://micro.magnet.fsu.edu/beershots/index.html">Molecular Expressions: The Beershots Photo Gallery</a></li>
        <li><a href="http://www.everythingneon.com/beer-neon-signs.php">Neon Beer Signs</a></li>
        <li><a href="http://www.pubcrawler.net/">PubCrawler</a></li>
        <li><a href="http://www.executivegiftshoppe.com/beer.html">Executive Gift Shop (beer gifts)</a></li>
        <li><a href="http://www.beertools.com/ap/stlbrews/store/product.php?prodid=310">BeerTools Software</a></li>
        <li><a href="http://beersmith.com/">BeerSmith Software</a></li>
        <li><a href="http://www.saintbrewis.com/">Saint Brewis</a></li>
        <li><a href="http://www.stlhops.com/">STL Hops</a></li>
    </ul>
    <h5><a href="#top">Back to Top</a></h5>
    <hr width="90%">

    <h3><a name="cat6">Members' Pages</a></h3>
    <p>
        These are some of our members' web pages.
    </p>
    <ul>
        <li><a href="http://www.home.earthlink.net/~ibrew4u">Rich Bingam's St. John Homebrewing</a></li><br>
            Check out Rich's SERIOUS homebrewery.
    </ul>
    <h5><a href="#top">Back to Top</a></h5>
    <hr width="90%">

    <h3><a name="cat7">Local Breweries, Brewpubs</a></h3>
    <p>
        Saint Louis metro area (and a little bit out of metro!)
    </p>
    <ul>
        <li><a href="http://twoplumbers.com/">2 Plumbers Brewery</a></li>
        <li><a href="http://www.2ndshiftbrewing.com/">2nd Shift Brewing</a></li>
        <li><a href="http://4handsbrewery.com/">4 Hands Brewing</a></li>
        <li><a href="http://www.mainstreetbrewingco.com/">4204 Main St. Brewing</a></li>
        <li><a href="http://www.alphabrewingcompany.com/">Alpha Brewing</a></li>
        <li><a href="https://www.facebook.com/augustabrewing">Augusta Brewing</a></li>
        <li><a href="https://www.bastardbrothersbrewery.com/">Bastard Brothers Brewery</a></li>
        <li><a href="http://www.bigmuddybrewing.com/">Big Muddy Brewing</a></li>
        <li><a href="https://www.facebook.com/boxingturtlebrewing/">Boxing Turtle Brewing</a></li>
        <li><a href="http://cathedralsquarebrewery.com/">Cathedral Square Brewery</a></li>
        <li><a href="http://www.centericebrewery.com/">Center Ice Brewery</a></li>
        <li><a href="http://www.charlevillevineyard.com/">Charleville Brewing</a></li>
        <li><a href="http://www.thecivillife.com/">Civil Life Brewing</a></li>
        <li><a href="http://www.crownvalleybrewery.com/">Crown Valley Brewing</a></li>
        <li><a href="http://www.deslogetown.com/">Deslogetown Brewery</a></li>
        <li><a href="http://www.earthboundbeer.com/">Earthbound Brewing</a></li>
        <li><a href="http://excelbottling.com/beer/#">Excel Bottling</a></li>
        <li><a href="http://exit6brewery.com/">Exit 6 Pub and Brewery</a></li>
        <li><a href="http://fergusonbrewing.com/">Ferguson Brewing</a></li>
        <li><a href="http://www.friendshipbrewco.com/">Friendship Brewing</a></li>
        <li><a href="https://www.goodnewsbrewing.com/">Good News Brewing</a></li>
        <li><a href="http://www.greerbrewingco.com/">Greer Brewing</a></li>
        <li><a href="http://gb-beer.com/">Griesedieck Bros. Brewery</a></li>
        <li><a href="http://www.heavyriffbrewing.com/">Heavy Riff Brewing</a></li>
        <li><a href="http://www.hopskellerbrewing.com/">Hopskeller Brewing</a></li>
        <li><a href="http://www.jstreetbrewco.com/">Jackson Street BrewCo</a></li>
        <li><a href="http://www.kirkwoodstationbrewing.com/">Kirkwood Station Brewing</a></li>
        <li><a href="http://www.logboatbrewing.com/">Logboat Brewing</a></li>
        <li><a href="https://www.facebook.com/mainandmillbrewingco/">Main & Mill</a></li>
        <li><a href="http://www.marktwainbrewery.com/">Mark Twain Brewing</a></li>
        <li><a href="http://www.mobeerco.com/">Missouri Beer Company</a></li>
        <li><a href="http://modernbrewery.com/ofage.html">Modern Brewery</a></li>
        <li><a href="http://www.morganstreetbrewery.com/">Morgan Street Brewery</a></li>
        <li><a href="http://www.narrowgaugestl.com/">Narrow Gauge Brewing</a></li>
        <li><a href="http://www.ofallonbrewery.com/">O'Fallon Brewery</a></li>
        <li><a href="http://www.oldbakerybeer.com/">Old Bakery Beer Co.</a></li>
        <li><a href="http://www.peelpizza.com/peel-brewing-co/">Peel Brewing</a></li>
        <li><a href="http://www.perennialbeer.com/">Perennial Artisan Ales</a></li>
        <li><a href="http://www.pointlabaddiebrewery.com/">Point Labaddie Brewery</a></li>
        <li><a href="http://www.prisonbrews.com/">Prison Brews</a></li>
        <li><a href="http://www.publichousebrewery.com/">Public House Brewing</a></li>
        <li><a href="http://www.recessbrewing.com/">Recess Brewing</a></li>
        <li><a href="http://www.rockwellbeer.com/">Rockwell Beer Co.</a></li>
        <li><a href="http://www.stnicholasbrewco.com/">Saint Nicholas Brewing</a></li>
        <li><a href="http://schlafly.com/bottleworks">Schlafly Bottleworks</a></li>
        <li><a href="http://schlafly.com/tap-room/">Schlafly Taproom</a></li>
        <li><a href="https://www.scratchbeer.com/">Scratch Brewing</a></li>
        <li><a href="http://www.sideprojectbrewing.com/">Side Project</a></li>
        <li><a href="http://www.sixmilebridgebeer.com/home/">Six Mile Bridge</a></li>
        <li><a href="http://www.squareonebrewery.com/">Square One Brewery</a></li>
        <li><a href="http://www.steampunkbrewworks.com/">Steampunk Brew Works</a></li>
        <li><a href="http://www.stubborngermanbrewing.com/">Stubborn German</a></li>	        
        <li><a href="http://www.thirdwheelbrewing.com/home">Third Wheel Brewing</a></li>
        <li><a href="http://www.tinmillbrewery.com/">Tin Mill Brewing</a></li>
        <li><a href="http://www.trailheadbrewing.com/">Trailhead Brewing</a></li>
        <li><a href="http://urbanchestnut.com/">Urban Chestnut Brewing</a></li>
        <li><a href="http://www.wellspentbeer.com/">Wellspent Brewing</a></li>
        <li><a href="http://www.whiteroosterfarmhousebrewery.com/">White Rooster Brewery</a></li>
    </ul>
    <h5><a href="#top">Back to Top</a></h5>
    <hr width="90%">

    <h3><a name="cat3">Large Commercial Breweries, Brewpubs</a></h3>
    <p>
        These are the guys that measure their beer by the  barrel.
    </p>
    <ul>
        <li><a href="http://www.anheuser-busch.com">Anheuser-Busch</a> or <a href="http://www.budweiser.com">Budweiser</a></li>
        <li><a href="http://www.badfrog.com">Bad Frog Brewery Company</a></li>
        <li><a href="http://www.beamish.ie/">Beamish Brewery</a></li>
        <li><a href="http://www.coors.com">Blue Moon Brewing Company</a></li>
        <li><a href="http://www.samueladams.com/">Boston Beer Company</a></li>
        <li><a href="http://www.boulevard.com">Boulevard Brewing Company</a></li>
        <li><a href="http://www.beerparadise.be/">Confederation of Belgian Breweries</a></li>
        <li><a href="http://www.murphys.com/">Murphy's Irish Stout</a></li>
        <li><a href="http://www.flatbranch.com/">Flat Branch</a></li>
        <li><a href="http://www.fostersbeer.com/">Foster's</a></li>
        <li><a href="http://www.fullsailbrewing.com/">Full Sail Brewing Company</a></li>
        <li><a href="https://www.guinness.com/en-us/">Guinness</a></li>
        <li><a href="http://heinekenusa.com">Heineken</a></li>
        <li><a href="http://www.ironbarley.com/">Iron Barley</a></li> 
        <li><a href="http://mcauslan.com/en/">McAuslan Brewing</a></li>
        <li><a href="http://www.millercoors.com/AgeVerification.aspx">Miller</a></li>
        <li><a href="http://www.paulaner.com/">Paulaner</a></li>
        <li><a href="http://pilsnerurquell.com">Pilsner Urquell</a></li>
        <li><a href="http://www.redhook.com/">Redhook Ale Brewery</a></li>
        <li><a href="http://www.shiner.com/">Spoetzl Brewery</a></li>
        <li><a href="http://www.youngs.co.uk">Young's Brewery</a></li>
   	    <li><a href="http://kraftig.com/">William K Busch Brewing Company</a></li> 
    </ul>
    <h5><a href="#top">Back to Top</a></h5>        
    <hr width="90%">

	<h3><a name="cat4">Beer Magazines</a></h3>
    <p>
        Magazines (and e-zines) dedicated to beer and brewing.
    </p>
    <ul>
        <li><a href="http://www.allaboutbeer.com/">All About Beer</a></li>
        <li><a href="http://www.byo.com/">Brew Your Own</a></li>
        <li><a href="http://brewingtechniques.com/">Brewing Techniques</a></li>
        <li><a href="http://celebrator.com/">Celebrator Beer News</a></li>
        <li><a href="http://www.homebrewersassociation.org/pages/zymurgy/current-issue">Zymurgy</a></li>
    </ul>
    <h5><a href="#top">Back to Top</a></h5>

</div>