<img src="assets/images/pixel.gif" width="1" height="5" alt="">

<div>
    <h3>Passwords</h3>

    <p>Below are the current passwords in the database.  Each year is assigned a different password, and it is 
        important that all years have an associcated password.  The entry with a value of -1 for the password
        contains the password for the admin section.</p>
    
    <br />

    <table  width=400 cellpadding="0" cellspacing="0">
        <tr>
            <td colspan=1 width=100 valign="top" align="center">
                <b>Year</b>
            </td>
            <td colspan=1 width=200 valign="top" align="center">
                <b>Password</b>
            </td>
        </tr>
    </table>
    <table width=400 cellpadding="0" cellspacing="0">
        <div *ngFor="let password of allPasswords">
            <tr>
                <td colspan=1 width=100 valign="top" align="center">
                    {{password.year}}
                </td>
                <td colspan=1 width=200 valign="top" align="center">
                    {{password.password}}
                </td>
            </tr>
        </div>
    </table>
    <br/>
    <br/>
    <p>To add a password for a new year, or to modify an existing password, please fill in the 
        year and password below.  Remember that a value of -1 for the year is for the admin password.
    </p>
    <br/>
    <p>Year: &nbsp; <input type = "text" id="year" name="year" [(ngModel)]="year"></p>

    <p>Password: &nbsp; <input type = "text" id="password" name="password" [(ngModel)]="password"></p>
    <br/>
    <button type="button" (click)="submit()">Submit</button>

</div>