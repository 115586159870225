<h4>Next Meeting</h4>
<div *ngIf="currentMeeting; else elseBlock">
    <p>
    Our next meeting is on <b>{{currentMeeting.meeting_date | date: 'EEEE, MMMM d, y'}} at 7:00 pm</b>.  This month's styles are {{currentMeeting.meeting_styles}}.
    The links at the bottom of the page contain more information about individual styles.  You can also get the schedule for the rest of the year.
    </p>
    </div>
<ng-template #elseBlock>
    <p>
        Our next meeting is on the first Thursday of the month at 7:00pm.  
        The links at the bottom of the page contain more information about individual styles.  You can also get the schedule for the rest of the year.
    </p>
</ng-template>






