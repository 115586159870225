<h4>Contact Us</h4>
<div *ngIf="recipients; else elseBlock">
    <div *ngFor='let recipient of recipients'>
            <a href="contactus/{{recipient.officer}}/about">{{recipient.name}}&nbsp;- {{recipient.officer}}</a><br />
    </div>
    <div >
        <a href="contactus/Officers/about">All Officers</a><br />
    </div>
</div>
<ng-template #elseBlock><i>Sorry - no officers in database at this time</i></ng-template>
<br/>
