import { IEmailContent } from './emailContent';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { on } from 'events';
import { IEmailContact } from './emailContact';
import { AppConfig } from '../AppConfig/AppConfig';

@Injectable( {
    providedIn: 'root'
})
export class EmailService {

     private emailApi = '/Email/SendEmail'
     private emailApiGet = '/Email/GetRecipients'

    constructor(private http: HttpClient) {
    }

    PostMessage(input: any): Observable<boolean> {

        const headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

        const thisEmail : IEmailContent = {
            from_name: input.Fullname,
            from_email: input.Email,
            to_officer: input.Recipient,
            subject: "Email from stlbrews.org",
            body: input.Comment
        };
 
        const finalUrl = AppConfig.settings.webApi.baseUrl + this.emailApi;
        return this.http.post<boolean>(finalUrl, JSON.stringify(thisEmail), {headers: headerOptions})
                .pipe(catchError(this.handleError.bind(this)));
    }

    getAllRecipients(): Observable<IEmailContact[]> {
        const finalUrl = AppConfig.settings.webApi.baseUrl + this.emailApiGet;
        return this.http.get<IEmailContact[]>(finalUrl).pipe(
            // tap(data=>console.log('Meetings JSON: ' + JSON.stringify(data))),
            catchError(this.handleError)
        );
        }
 
    handleError(errorResponse: HttpErrorResponse) {
        if (errorResponse.error instanceof ErrorEvent) {
           console.error('Client Side Error :', errorResponse.error.message);
        } else {
          console.error('Server Side Error :', errorResponse);
        }
   
       // return an observable with a meaningful error message to the end user
       return throwError('There is a problem with the service.  We have been notified & working on it.Please try again later.');
      }
   
}




   

