import { expressionType } from "@angular/compiler/src/output/output_ast";
import { Component } from "@angular/core";
// import { ESPIPE } from "constants";
import { IEvent } from "./event";
import { EventsService } from './event.service'

@Component({
    selector: 'app-home-events',
    templateUrl: './events.component.html',
    styleUrls: ['../app.component.css']
  })
export class EventsComponent {
    currentEvents : IEvent[];
    errorMessage : String;

    constructor(private eventsService: EventsService) {
    }
   
    ngOnInit() : void {
       
        this.eventsService.getEvents().subscribe({
            next: events => this.currentEvents = this.FilterAndSort(events),
            error: err => this.errorMessage  = err
        });


    }

    FilterAndSort(events: IEvent[]) : IEvent[] {
        events = events.filter(function(obj) {
          return new Date(obj.start_date) <= new Date() && new Date(obj.end_date) >= new Date();
        });
       events.sort((a,b) => (new Date(a.event_date) > new Date(b.event_date)) ? 1 : -1);
        return events;
    }
    
}
