import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

  
@Component({
  templateUrl: './meadme.component.html',
  styleUrls: ['../app.component.css','./meadme.component.css']
  
})
export class MeadMeComponent {

    constructor() {
              }
   
    form = new FormGroup({
        website: new FormControl('', Validators.required)
      });
    get f(){
        return this.form.controls;
    }

    
    ngOnInit() : void {
    }


}
