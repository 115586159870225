import { Component } from '@angular/core';

@Component({
  templateUrl: './admin.component.html',
  styleUrls: ['../app.component.css', './admin.component.css']
})
export class AdminComponent {
    public pageTitle = 'Admin';

}
