import { Component } from '@angular/core';

@Component({
  templateUrl: './members.component.html',
  styleUrls: ['../app.component.css', './members.component.css']
})
export class MembersComponent {
    public pageTitle = 'Members-Only';

}


