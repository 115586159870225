import { AdminPasswordsComponent } from './Admin/passwords.component';
import { AdminLoginComponent } from './Admin/adminlogin.component';
import { AdminComponent } from './Admin/admin.component';
import { AdminLoginService } from './Admin/adminlogin.service';
import { OfficersComponent } from './About/officers.component';
import { CalendarComponent } from './Calendar/calendar.component';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
  
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './Footer/footer.component';
import { LeftNavComponent } from './LeftNav/leftNav.component';
import { HomeComponent } from './Home/home.component';
import { EventsComponent } from './Home/events.component';
import { MeetingComponent } from './Home/meeting.component';
import { AboutComponent } from './About/about.component';
import { SupportersComponent } from './Supporters/supporters.component';
import { LinksComponent } from './Links/links.component';
import { CompetitionsComponent } from './Competitions/competitions.component';
import { MeadMeComponent } from './Competitions/meadme.component';
import { LoginComponent } from './MembersOnly/login.component';
import { MembersComponent } from './MembersOnly/members.component';
import { LoginService } from './MembersOnly/login.service';
import { EmailService } from './Email/email.service';
import { MembersGuard } from './MembersOnly/members.guard';
import { AdminGuard } from './Admin/admin.guard';
import { NewsletterComponent } from './MembersOnly/newsletter.component';
import { EmailComponent } from './Email/email.component';
import { ContactUsComponent } from './ContactUs/contact-us.component';
import { AppConfig } from './AppConfig/AppConfig';
export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    LeftNavComponent,
    HomeComponent,
    EventsComponent,
    MeetingComponent,
    AboutComponent,
    SupportersComponent,
    LinksComponent,
    CompetitionsComponent,
    MeadMeComponent,
    LoginComponent,
    MembersComponent,
    CalendarComponent,
    NewsletterComponent,
    EmailComponent,
    OfficersComponent,
    ContactUsComponent,
    AdminComponent,
    AdminLoginComponent,
    AdminPasswordsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: 'home', component: HomeComponent },
      { path: 'about', component: AboutComponent },
      { path: 'supporters', component: SupportersComponent },
      { path: 'links', component: LinksComponent },
      { path: 'competitions', component: CompetitionsComponent },
      { path: 'meadme', component: MeadMeComponent },
      { path: 'login', component: LoginComponent },
      { path: 'members', component: MembersComponent, canActivate: [MembersGuard] },
      { path: 'newsletter', component: NewsletterComponent, canActivate: [MembersGuard] },
      { path: 'calendar', component: CalendarComponent },
      { path: 'contactus/:recipient/:from', component: ContactUsComponent },
      { path: 'adminlogin', component: AdminLoginComponent },
      { path: 'admin', component: AdminComponent, canActivate: [AdminGuard]  },
      { path: 'adminPasswords', component: AdminPasswordsComponent, canActivate: [AdminGuard]  },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: '**', redirectTo: 'home', pathMatch: 'full' }
    ])
  ],
  providers: [LoginService, 
    AdminLoginService,
    EmailService,
    AppConfig,
    { provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
