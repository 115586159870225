import { AppConfig } from './../AppConfig/AppConfig';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators'

import { ICompetition } from './competition';

@Injectable( {
    providedIn: 'root'
})
export class CompetitionService {

    private competitionUrl = '/Competitions';

    constructor(private http: HttpClient) {}

    getCompetitions(): Observable<ICompetition[]> {
        const finalUrl = AppConfig.settings.webApi.baseUrl + this.competitionUrl;
        return this.http.get<ICompetition[]>(finalUrl).pipe(
            // tap(data=>console.log('All: ' + JSON.stringify(data))),
            catchError(this.handleError)
        );
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = 'An error occurred: ${err.error.message}';
        } else {
            errorMessage = 'Server returned code: ${err.status}, error message isL ${err.message}';
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }


}
