<img src="assets/images/pixel.gif" width="1" height="5" alt="">

<div>
    <table border="0" width=620 cellpadding="0" cellspacing="0">
        <tr>
            <td colspan=1 width=600 valign="top" align="left">
                <h4>
                   Welcome to the Admin section.
                </h4>
                <br/>
            
                <h4>
                    <a href="adminCompetitions">Competitions</a>
                </h4>
                <p>
                Set information about upcoming competitions.
                </p>
                <br/>
                <h4>
                    <a href="adminWinners">Winners</a>
                </h4>
                <p>
                Maintain information about competition winners.
                </p>
                <br/>
                <h4>
                    <a href="adminEmail">Emails</a>
                </h4>
                <p>
                Set email information for officers.
                </p>
                <br/>
                <h4>
                    <a href="adminEvents">Events</a>
                </h4>
                <p>
                Set information about upcoming events.
                </p>
                <br/>
                <h4>
                    <a href="adminMeetings">Meetings</a>
                </h4>
                <p>
                Set information about upcoming meetings.
                </p>
                <br/>
                <h4>
                    <a href="adminNewsletters">Newsletters</a>
                </h4>
                <p>
                Maintain list of newsletters for members-only section.
                </p>
                <br/>
                <h4>
                    <a href="adminPasswords">Password</a>
                </h4>
                <p>
                Set password for members-only section.
                </p>
                <br/>
            </td>
        </tr>
    </table>
 
 </div>