import { Injectable, ɵsetCurrentInjector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, filter, subscribeOn, tap } from 'rxjs/operators'

import { IEvent } from './event';
import { AppConfig } from '../AppConfig/AppConfig';

@Injectable( {
    providedIn: 'root'
})
export class EventsService {

    private eventsUrl = '/UpcomingEvents';

    constructor(private http: HttpClient) {}

   getEvents(): Observable<IEvent[]> {
    const finalUrl = AppConfig.settings.webApi.baseUrl + this.eventsUrl;
    return this.http.get<IEvent[]>(finalUrl).pipe(
        // tap(data=>console.log('All: ' + JSON.stringify(data))),
        catchError(this.handleError),
    );
     }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = 'An error occurred: ${err.error.message}';
        } else {
            errorMessage = 'Server returned code: ${err.status}, error message isL ${err.message}';
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }

    FilterAndSort(events: IEvent[]) : IEvent[] {
        events = events.filter(function(obj) {
          return new Date(obj.start_date) <= new Date() && new Date(obj.end_date) >= new Date();
        });
       events.sort((a,b) => (new Date(a.event_date) > new Date(b.event_date)) ? 1 : -1);
        return events;
    }

}
